@import "variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap");

.home {
  height: 100vh;
  position: relative;
  background: linear-gradient(
    120deg,
    rgba(234, 242, 248, 1) 0%,
    rgba(219, 237, 250, 1) 75%,
    rgba(177, 212, 237, 1) 100%
  );

  &__photo {
    position: absolute;
    top: 8vh;
    right: 13vw;
    background: white;
    border-radius: 3px;
    padding: 15px;
    z-index: 3;

    @include lg {
      right: 3vw;
      top: 7vh;
    }

    @include md {
      padding: 10px;
    }

    @include sm {
      top: 5vh;
    }

    img {
      width: auto;
      height: 80vh;

      @include lg {
        width: 45vw;
        height: auto;
      }

      @include md {
        width: 63vw;
      }
    }
  }

  &__intro {
    position: absolute;
    top: 17vh;
    left: 20vw;
    width: 60vw;
    height: 70vh;
    background: white;
    border-radius: 3px;
    z-index: 4;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
    text-align: right;
    padding: 90px 110px 90px 90px;

    @include xxl {
      padding: 90px 110px 90px 90px;
    }

    @include xl {
      padding: 50px;
    }

    @include lg {
      width: 70vw;
      height: 65vh;
      top: unset;
      bottom: 10vh;
      left: 7vw;
      padding: 35px 50px;
    }

    @include md {
      height: 50vh;
    }

    @include sm {
      height: 70vh;
      width: 85vw;
      bottom: 5vh;
      left: 3.5vw;
      padding: 50px 25px;
    }

    @include xs {
      padding: 25px;
    }
  }

  &__name {
    font-family: "Titillium Web", sans-serif;
    font-size: 88px;
    font-weight: 600;
    color: $titleColor;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 100px;

    @include xxl {
      font-size: 80px;
    }

    @include xl {
      font-size: 75px;
      line-height: 90px;
    }

    @include lg {
      font-size: 70px;
    }

    @include sm {
      font-size: 48px;
      line-height: 54px;
    }

    @include xs {
      font-size: 40px;
      line-height: 40px;
    }
  }

  &__content {
    color: $mainLightGrey;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 60px;
    font-weight: 600;

    @include xl {
      font-size: 20px;
      margin-bottom: 50px;
    }

    @include lg {
      font-size: 18px;
    }

    @include xs {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }

  &__info {
    color: $primaryColor;
    font-size: 20px;
    font-weight: 400;

    a {
      color: $primaryColor;

      &:active,
      &:hover {
        color: $primaryColor;
      }
    }

    @include xl {
      font-size: 16px;
    }

    @include xs {
      font-size: 14px;
    }
  }

  &__square {
    background-color: $primaryColor;
    width: 40vw;
    height: 85vh;
    position: absolute;
    right: 25vw;
    top: 10vh;
    z-index: 2;
    transform: rotate(255deg);
    border-radius: 3px;

    @include lg {
      height: 52vh;
      width: 48vw;
      right: 11vw;
      top: 7vh;
    }

    @include md {
      width: 63vw;
      top: 7vh;
    }

    @include sm {
      top: 5vh;
    }
  }

  &__frame {
    border: 10px solid $mainDarkBlue;
    width: 60vw;
    height: 70vh;
    position: absolute;
    right: 10vw;
    top: 15vh;
    z-index: 3;
    transform: rotate(10deg);

    @include xl {
      width: 50vw;
    }

    @include lg {
      border: 8px solid $mainDarkBlue;
      right: 8vw;
      height: 60vh;
      width: 65vw;
      top: 10vh;
    }

    @include md {
      border: 5px solid $mainDarkBlue;
      width: 78vw;
      height: 60vh;
      top: 8vh;
      right: 0;
    }

    @include sm {
      top: 4vh;
      height: 50vh;
    }
  }
}
