@import "variables.scss";

.skills {
  background-color: white;
  border-radius: 3px;
  box-shadow: 5px 5px 5px 0px rgba(168, 168, 168, 0.75);
  width: 70vw;
  margin: auto;
  margin-bottom: 80px;
  padding: 100px 60px 80px;
  position: relative;

  @include lg {
    width: 80vw;
    padding: 100px 40px 80px;
  }

  @include md {
    width: 85vw;
    padding: 70px 25px 40px;
  }

  @include sm {
    width: 95vw;
    padding: 70px 10px 20px;
  }
}

.skills-content {
  padding-bottom: 20px;

  &__title {
    color: $mainLightGrey;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    font-family: "Titillium Web", sans-serif;

    @include sm {
      margin-bottom: 15px;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .others {
    margin-top: 20px;
    justify-content: left;

    span {
      padding: 2px 8px;
      border-radius: 3px;
      background-color: rgba(204, 158, 120, 0.7);
      color: white;
      font-weight: 600;
      margin: 0 8px 8px 0;
      letter-spacing: 0.5px;

      @include sm {
        margin: 0 5px 5px 0;
      }
    }
  }

  .language {
    margin-top: 20px;

    p {
      padding: 2px 8px;
      border-radius: 3px;
      background-color: rgba(148, 152, 156, 0.6);
      color: white;
      font-weight: 600;
      margin: 0 4px 8px 4px;
      letter-spacing: 0.5px;

      @include sm {
        margin: 0 3px 5px 3px;
      }
    }
  }
}

.skills-title {
  background: $titleColor;
  color: white !important;
  width: 350px;
  height: 70px;
  line-height: 70px;
  position: absolute;
  top: 10px;
  left: -100px;
  transform: rotate(350deg);

  @include lg {
    left: -50px;
  }

  @include md {
    transform: rotate(0deg);
    position: absolute;
    left: calc(50% - 175px);
    top: -30px;
  }
}

.ant-progress {
  margin: 20px 10px;
  text-align: center;

  @include sm {
    margin: 10px;
  }
}

.ant-progress-inner {
  width: 100px !important;
  height: 100px !important;
  @include sm {
    width: 80px !important;
    height: 80px !important;
  }
}

.ant-progress-circle .ant-progress-text {
  font-size: 16px;
  font-weight: 700;
  word-break: break-word;
  padding: 0 5px;
  @include sm {
    font-size: 14px;
  }
}

.fullstack .ant-progress:nth-child(1),
.fullstack .ant-progress:nth-child(2),
.fullstack .ant-progress:nth-child(3) {
  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: #70a582;
  }

  .ant-progress-text {
    color: #70a582;
  }
}

.fullstack .ant-progress:nth-child(4) {
  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: #7093a5;
  }

  .ant-progress-text {
    color: #7093a5;
  }
}

.fullstack .ant-progress:nth-child(5),
.fullstack .ant-progress:nth-child(6) {
  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: rgb(129, 124, 141);
  }

  .ant-progress-text {
    color: rgb(129, 124, 141);
  }
}

.frontend .ant-progress:nth-child(1),
.frontend .ant-progress:nth-child(2),
.frontend .ant-progress:nth-child(3),
.frontend .ant-progress:nth-child(4) {
  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: #70a582;
  }

  .ant-progress-text {
    color: #70a582;
  }
}

.frontend .ant-progress:nth-child(5),
.frontend .ant-progress:nth-child(6),
.frontend .ant-progress:nth-child(7),
.frontend .ant-progress:nth-child(8) {
  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: #7093a5;
  }

  .ant-progress-text {
    color: #7093a5;
  }
}

.frontend .ant-progress:nth-child(9),
.frontend .ant-progress:nth-child(10),
.frontend .ant-progress:nth-child(11),
.frontend .ant-progress:nth-child(12),
.frontend .ant-progress:nth-child(13) {
  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: rgb(129, 124, 141);
  }

  .ant-progress-text {
    color: rgb(129, 124, 141);
  }
}

.app .ant-progress:nth-child(1),
.app .ant-progress:nth-child(2),
.app .ant-progress:nth-child(3) {
  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: rgb(176, 121, 146);
  }

  .ant-progress-text {
    color: rgb(176, 121, 146);
  }
}
