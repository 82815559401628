@import "variables.scss";

.top-nav {
  position: absolute;
  top: 0;
  font-family: "Titillium Web", sans-serif;
  text-align: center;
  height: 60px;
  line-height: 60px;
  background: $mainDarkBlue;
  font-size: 20px;
  border-bottom: 2px solid $mainDarkBlue;
  letter-spacing: 1.5px;
  z-index: 200;
  width: 100vw;
  transition: 0.5s;

  @include xl {
    font-size: 16px;
  }

  a {
    color: white !important;
    padding: 0 40px;
  }

  .ant-menu-item {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 0 40px;
    border-bottom: none;
    top: 0;
    font-weight: 600;

    &:hover {
      border-bottom: none !important;
      color: white !important;
      background-color: rgba(11, 66, 121, 0.8) !important;
    }
  }

  .ant-menu-item-selected {
    border-bottom: none !important;
    background-color: rgb(11, 66, 121) !important;

    a {
      color: white !important;
    }
  }
}
