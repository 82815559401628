@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap');


.App {
  overflow-x: hidden;
}

.footer {
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
  font-size: 12px;
  color: white;
  letter-spacing: 1.5px;
  height: 32px;
  background-color: black;
  line-height: 32px;
}