@import "variables.scss";

.about-me {
  padding: 140px 0 130px;
  position: relative;

  @include md {
    padding: 80px 0;
  }

  &__content {
    width: 70vw;
    margin: auto;
    text-align: left;
    font-size: 18px;

    @include xl {
      font-size: 16px;
    }

    @include lg {
      width: 80vw;
    }

    @include sm {
      width: 90vw;
      line-height: 24px;
    }

    p {
      margin-bottom: 24px;
    }

    // p::first-letter {
    //   font-weight: 700;
    //   font-size: 330%;
    //   float: left;
    //   width: 40px;
    //   padding-right: 5px;
    //   line-height: 0.9;
    //   color: $mainLightGrey
    // }

    // p:first-child::first-letter {
    //   margin-top: -27px;

    //   @include xl {
    //     margin-top: -24px
    //   }
    // }
  }
}
